import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center space-y-4 py-[128px]">
        <h1 className="text-coeusRed font-semibold text-5xl">Not found</h1>
        <p className="text-lg font-normal">You just hit a route that doesn't exist...</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => {
  return (
    <SEO
      title="Customer Service AI Platform"
      description="Coeus is a customer service platform that utilizes Conversational AI to provide a unique, high-quality customer experience."
    ></SEO>
  );
};
